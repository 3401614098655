body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fa-margin-fix {
  margin-left: 2px;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
  overflow: hidden;
}

.sidebar-content {
  overflow-x: hidden !important;
}

.page-title {
  padding: 1.25rem 0 !important;
}

.paging_simple_numbers, .dataTables_info {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.iti__country > span {
  color: black !important;
}
